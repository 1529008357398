<template>
    <div class="loading">
        <span class="more-blog-loading-icon"></span>
    </div>
</template>

<script>
    export default {
        name: 'Loader'
    }
</script>