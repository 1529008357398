<template>
    <button v-if="!isLoadingMore && !isLoading" type="button" class="button load-more-products" @click="$emit('loadMore')">
        LOAD MORE
    </button>
    <a v-else :style="{ display: isLoadingMore ? 'block' : 'none', opacity: isLoadingMore ? 1 : 0, textAlign: 'center' }" :class="{ loading: isLoadingMore }">
        Loading more...
        <loader />
    </a>
</template>
<script>
import Loader from "./Loader";
export default {
    components: {Loader},
    props: {
        isLoading: Boolean,
        isLoadingMore: Boolean
    }
}
</script>
