var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: {
        "product-listing samples-layout": true,
        active: _vm.sample.isInCart
      }
    },
    [
      _c("a", { attrs: { role: "button" } }, [
        _c("div", { staticClass: "product-image" }, [
          _c("img", {
            staticClass: "attachment-medium_square size-medium_square",
            attrs: {
              src: _vm.sample.image,
              width: "400",
              height: "400",
              alt: "",
              sizes: "(max-width: 400px) 100vw, 400px"
            }
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "addRemoveCart" }, [
            _c(
              "span",
              {
                staticClass: "addCart",
                on: {
                  click: function($event) {
                    return _vm.$emit("add-sample", $event, _vm.sample)
                  }
                }
              },
              [
                _c("i", { staticClass: "icon-plus" }),
                _vm._v(" ADD TO CART\n                ")
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "removeCart",
                on: {
                  click: function($event) {
                    return _vm.$emit("remove-sample", _vm.sample, _vm.index)
                  }
                }
              },
              [
                _c("i", { staticClass: "icon-minus" }),
                _vm._v(" REMOVE FROM CART\n                ")
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "detail-product" }, [
          _vm._v("\n            " + _vm._s(_vm.sample.title)),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.sample.product.title))])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overlay-inner" }, [
      _c("form", [_c("legend", [_c("i", { staticClass: "icon-cart-sample" })])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }