<template>
    <ul v-if="!isLoading && samples.length" class="large-block-grid-4 medium-block-grid-3 product-list product-list-large">
        <li
            is="sample"
            v-for="(sample, index) in samples"
            :index="index"
            v-bind:key="sample.id"
            v-bind:sample="sample"
            v-on:add-sample="handleAddSample"
            v-on:remove-sample="handleRemoveSample"
        ></li>
    </ul>
</template>
<script>
import Sample from './Sample'

export default {
    props: {
        isLoading: Boolean,
        samples: Array
    },
    components: {
        Sample
    },
    methods: {
        handleAddSample(event, sample) {
            this.$emit('add-sample', sample)
        },
        handleRemoveSample(sample, index) {
            this.$emit('remove-sample', sample, index)
        }
    }
}
</script>
