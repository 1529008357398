<template>
    <div id="sample-nav-contain" data-magellan-expedition="fixed">
        <div class="sample-sticky-bar">
            <div class="row inner collapse">
                <div class="columns small-12 medium-5 large-5">
                    <div class="selected-samples">
                        <span
                            class="num-selected-samples">{{cartInfo.totalQty}}</span>/<span
                        id="max-samples">{{max}}</span> samples selected
                    </div>
                </div>
                <div class="columns small-12 medium-7 large-7">
                    <div class="samples-cart">
                        <div class="left groupLR">
                            <ul>
                                <li :class="{ 'for-step1': true, 'hide': step !== 1 }">
                                    <span>PROCEED TO CHECKOUT</span>
                                </li>
                                <li :class="{ 'for-step2': true, 'hide': step !== 2 }" @click="$emit('go-to-checkout')">
                                    <button
                                        class="button button-point point-right"
                                        data-step2 id="proceed-sample-btn">
                                        PROCEED TO CHECKOUT
                                    </button>
                                </li>
                                <!-- <li :class="{ 'for-step3': true, 'hide': step !== 3 }" @click="$emit('back-to-samples')">
                                    <button
                                        class="button button-point point-left"
                                        data-step1>GO BACK TO SAMPLES
                                    </button>
                                </li> -->
                            </ul>
                        </div>
                        <div class="right groupLR">
                            <div class="cart">
                                <i class="icon-cart-sample"></i>
                                <span class="in-cart">{{cartInfo.totalQty}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        props: [
            'cart',
            'max',
            'step',
            'cartInfo'
        ],
        methods: {}
    }
</script>
