<template>
    <div>
        <ul v-if="!isLoading && productCollections.length && !error.hasError" class="product-list clearfix" data-equalizer>
            <ProductItem v-for="collection in productCollections" :key="collection.id" :collection="collection" />
        </ul>
        <div v-else-if="error.hasError && !isLoading" class="no-results error-state">
            There was an error, please try again or contact us if this keeps happening.
            <span class="error-message">{{ error.message }}</span>
        </div>
        <div v-else-if="!isLoading && !productCollections.length" class="no-results">
            No results.
        </div>
        <div v-if="isLoading" class="small-12 text-center">
            <a id="blog-load-more" :style="{ display: isLoading ? 'block' : 'none', opacity: isLoading ? 1 : 0 }" :class="{ loading: isLoading }">
                Loading products...
                <span class="more-blog-loading-icon"></span>
            </a>
        </div>
    </div>
</template>

<script>
import ProductItem from './ProductItem'

export default {
    props: {
        productCollections: Array,
        isLoading: Boolean,
        error: Object
    },
    components: {
        ProductItem
    }
}
</script>

