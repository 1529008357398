var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-color-block",
      style: { backgroundImage: "url('" + _vm.getImage(_vm.colorOption) + "')" }
    },
    [
      _c(
        "a",
        {
          staticClass: "inner-product-block",
          attrs: {
            href: _vm.colorOption.collection
              ? _vm.colorOption.collection.url + "#" + _vm.colorOption.slug
              : "#"
          }
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm.colorOption.title))]),
          _vm._v(" "),
          _vm.colorOption.collection
            ? _c("span", { staticClass: "divider" })
            : _vm._e(),
          _vm._v(" "),
          _vm.colorOption.collection
            ? _c("h5", [_vm._v(_vm._s(_vm.colorOption.collection.title))])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.colorOption.variant && _vm.colorOption.hasSample
        ? _c(
            "button",
            {
              staticClass: "add-to-cart",
              on: {
                click: function($event) {
                  return _vm.$emit(
                    "add-to-cart",
                    _vm.colorOption.variant,
                    _vm.index
                  )
                }
              }
            },
            [
              _vm.colorOption.isInCart
                ? _c("span", { staticClass: "show-text" }, [
                    _vm._v("Sample Added To Cart")
                  ])
                : _vm.samplesInCart.length === 3
                ? _c("span", [_vm._v("3/3 Samples Selected")])
                : _c("span", [_vm._v("Add Sample")]),
              _vm._v(" "),
              _c("img", { attrs: { src: _vm.icons.addToCart, alt: "" } })
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }