<template>
    <div class="product-color-block" :style="{ backgroundImage: `url('${getImage(colorOption)}')` }">
        <a :href="colorOption.collection ? `${colorOption.collection.url}#${colorOption.slug}` : '#'" class="inner-product-block">
            <h3>{{ colorOption.title }}</h3>
            <span v-if="colorOption.collection" class="divider"></span>
            <h5 v-if="colorOption.collection">{{ colorOption.collection.title }}</h5>
        </a>
        <button v-if="colorOption.variant && colorOption.hasSample" class="add-to-cart" @click="$emit('add-to-cart', colorOption.variant, index)">
            <span v-if="colorOption.isInCart" class="show-text">Sample Added To Cart</span>
            <span v-else-if="samplesInCart.length === 3">3/3 Samples Selected</span>
            <span v-else>Add Sample</span>
            <img :src="icons.addToCart" alt="">
        </button>
    </div>
</template>
<script>
    import iconAddToCart from '../../images/svgs/add-to-cart.svg'
export default {
    props: {
        colorOption: Object,
        samplesInCart: Array,
        index: Number
    },
    data() {
        return {
            icons: {
                addToCart: iconAddToCart
            }
        }
    },
    methods: {
        getImage(colorOption) {
            if (colorOption.colorOptionPhoto) {
                return colorOption.colorOptionPhoto
            } else if (colorOption.collection && colorOption.collection.productImage) {
                return colorOption.collection.productImage
            } else {
                return null
            }
        }
    }
}
</script>
