var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "products-container" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("ViewToggle", {
          attrs: { blockViewActive: _vm.blockViewActive },
          on: { toggleProductView: _vm.toggleProductView }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("ProductFilters", {
          attrs: {
            filters: _vm.filters,
            metaFilters: _vm.metaFilters,
            popoutOpen: _vm.popoutOpen
          },
          on: {
            togglePopout: _vm.togglePopout,
            metaFiltersChanged: _vm.handleChangeMetaFilters
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "product-listing-container small-12 large-8 columns" },
          [
            !_vm.blockViewActive
              ? _c("ProductRows", {
                  attrs: {
                    productCollections: _vm.productCollections,
                    isLoading: _vm.isLoading,
                    error: _vm.error
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.blockViewActive
              ? _c("ProductBlocks", {
                  attrs: {
                    productColorOptions: _vm.productColorOptions,
                    isLoading: _vm.isLoading,
                    error: _vm.error,
                    samplesInCart: _vm.samplesInCart
                  },
                  on: { "add-to-cart": _vm.addSampleToCart }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "small-12 columns load-more-container" },
              [
                _vm.pagination.total_pages > _vm.pagination.current_page
                  ? _c("LoadMoreButton", {
                      attrs: {
                        isLoadingMore: _vm.isLoadingMore,
                        isLoading: _vm.isLoading
                      },
                      on: { loadMore: _vm.handleLoadMore }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }