<template>
    <ul class="color-points">
        <label v-for="(color, index) in colors" :key="color.id + 273" :for="color.id">
            <li v-bind:style="{ background: `linear-gradient(to right, ${ gradient(color, colors[index + 1]) })` }"></li>
        </label>
    </ul>
</template>
<script>
export default {
    props: {
        colors: Array
    },
    methods: {
        gradient(color, nextColor) {
            return `${color.hexCode}, ${ nextColor ? nextColor.hexCode : '#9b9b9b' }`
        }
    }
}
</script>
