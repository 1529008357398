<template>
    <div :class="{'gallerycard': true, 'gallerycard--embedded': this.embedded}">
        <a href="#" v-on:click="handleClick" class="gallerycard__imagecontainer">
            <img
                    :src="photo[0]['url']" :alt="title"
                    :srcset="(photo.map((image) => {
                        return image.url + ' ' + image.width + 'w'
                    }).join(','))"
                    sizes="(max-width: 1000px) 1400px,
         700px"
            />
            <div>{{location}}</div>
        </a>
        <div class="gallerycard__content">
        <a href="#" v-on:click="handleClick" class="gallerycard__title">{{title}}</a>
        <div class="gallerycard__description" v-html="description"></div>
        <div v-for="productBlock in validProductBlocks" :key="productBlock.id">
            <div class="gallerycard__productname" v-if="productBlock.product"><a :href="productBlock.product.url" target="_blank">{{productBlock.product.title}}</a></div>
            <div class="gallerycard__colors">
                <ul>
                    <li v-for="color in productBlock.featuredColors" :key="color.id"><a :href="productBlock.product.url + '#' + color.slug"><img
                            :alt="color.title"
                            :src="color.colorOptionPhoto['1x']"
                            :srcset="color.colorOptionPhoto['1x'] + ' 1x, ' + color.colorOptionPhoto['2x']"
                    /><span>{{color.title}}</span></a></li>
                </ul>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            handleClick(e) {
                e.preventDefault()
                this.$eventBus.$emit('view-project', {
                    slug: this.slug
                });
            }
        },
        computed: {
            validProductBlocks() {
                return this.products.filter((productBlock) => {
                    return productBlock.product != null;
                });
            }
        },
        props: {
            embedded: Boolean,
            id: String,
            slug: String,
            title: String,
            location: String,
            description: String,
            photo: Array,
            products: Array
        }
    }
</script>



<style type="text/scss" scoped>
    @import "../../sass/settings";

    .gallerycard:hover {
        .gallerycard__title {
            text-decoration: underline;
        }
    }

    .gallerycard__imagecontainer {
        display: block;
        position: relative;
        margin-bottom: 20px;
    }

    .gallerycard__imagecontainer > div {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $primary-color;
        color: #fff;
        padding: 2px 8px;
        font-size: 12px;
        font-family: $font-family-avenir;
        height: 20px;
    }

    .gallerycard__imagecontainer > img {
        width: 100%;
    }

    .gallerycard__title {
        text-decoration: none;
        text-transform: uppercase;
        color: $primary-color;
        font-weight: bold;
        font-size: 21px;
        font-family: $font-family-grotesque;
        margin-bottom: 4px;
    }

    .gallerycard__description {
        font-family: $font-family-avenir;
        color: rgba(104, 107, 110, 0.5);
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 20px;
        border-bottom: 2px solid rgba(104, 107, 110, 0.3);
        margin-bottom: 22px;
    }

    .gallerycard__productname {
        font-family: $font-family-avenir;
        font-weight: bold;
        font-size: 18px;
        line-height: 19px;
        color: rgba(46, 41, 37, 0.6);
        a {
            color: unset;
            text-decoration: unset;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .gallerycard__colors {
        ul li {
            list-style: none;
            margin-top: 14px;
            a {
                text-decoration: none;
                display: flex;
                align-items: center;
            }
            &:hover {
                a {
                    text-decoration: underline;
                }
            }

            img {
                border-radius: 100%;
                border: 2px solid #686B6E;
                width: 44px;
                height: 44px;
            }

            span {
                display: block;
                color: rgba(46, 41, 37, 0.6);
                font-size: 18px;
                font-family: $font-family-avenir;
                line-height: 1;
                margin-left: 14px;
                padding-top: 4px; // For the image border
            }
        }

        border-bottom: 2px solid rgba(104, 107, 110, 0.3);
        margin-bottom: 50px;
    }
    .gallerycard--embedded {
        $border-radius: 12px;
        transition: box-shadow ease-in 0.25s;
        box-shadow: 0 0 10px 0 rgba(104,107,110,0.32);
        &:hover {
            box-shadow: 0 0 12px 0 rgba(104,107,110,1);
            .gallerycard__title {
                text-decoration: none;
            }
        }
        border-radius: $border-radius;
        background: #fff;
        .gallerycard__imagecontainer > img {
            border-radius: $border-radius $border-radius 0 0;
        }
        .gallerycard__imagecontainer > div {
            border-radius: 0 $border-radius 0 0;
        }
        .gallerycard__content {
            padding: 0 26px 7px;
        }
        .gallerycard__colors {
            margin-bottom: 0;
            border: none;
            li > span {
                font-size: 18px;
                line-height: 25px;
            }
            img {
                width: 35px;
                height: 35px;
            }
        }
    }
</style>