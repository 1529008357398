<template>
    <div>
        <sample-nav :cart="cart" :max="3" :step="step" :cartInfo="cartInfo"
            v-on:go-to-checkout="goToCheckout"
            v-on:back-to-samples="backToSamples"
        ></sample-nav>
        <div v-if="step != 3" class="panel-container">
            <sample-list
                :isLoading="isLoading"
                :samples="samples"
                v-on:add-sample="addSample"
                v-on:remove-sample="removeSample">
            </sample-list>
        </div>
        <div v-if="step === 3" class="panel-container">
            <checkout-screen
                :isLoading="isLoading"
                :isLoadingSamples="isLoadingSamples"
                :cart="cart"
                :error="error"
                v-on:back-to-samples="backToSamples"
                v-on:remove-sample="removeSample"
                v-on:submit-order="submitOrder"
            >
            </checkout-screen>
        </div>
    </div>
</template>
<script>
    import findKey from 'lodash/findKey'
    import find from 'lodash/find'

    import shortid from 'shortid'
    import samplesAPI, { paramConversion } from '../../js/components/sampleRequest/api'
    import { Cancel } from 'axios'
    import { updateCartQuantity } from '../../js/components/globalProductConfig/cartButtonEvents'

    import SampleNav from './SampleNav'
    import CheckoutScreen from './CheckoutScreen'
    import SampleList from './SampleList'

    export default {
        data() {
            return {
                orderSubmitted: false,
                checkingOut: true,
                isLoading: false,
                isLoadingSamples: false,
                cart: [],
                samples: [],
                error: {
                    hasError: false,
                    message: ''
                },
                checkoutForm: {
                    email: ''
                },
                cartInfo: {
                    totalQty: 0
                }
            }
        },
        components: {
            SampleNav,
            CheckoutScreen,
            SampleList
        },
        mounted() {
            // Get the original set of samples
            this.getSamples()
        },
        computed: {
            step() {
                if (this.checkingOut) {
                    return 3
                } else if (this.cart.length) {
                    return 2
                } else {
                    return 1
                }
            }
        },
        watch: {
            orderSubmitted(newValue) {
                if (newValue) {
                    window.location = '/cart/thank-you'
                }
            }
        },
        methods: {
            backToSamples() {
                this.checkingOut = false
            },
            submitOrder(checkoutForm) {
                if (!this.cart.length) {
                    this.error.hasError = true
                    this.error.message = 'You have no items in your cart.'
                    return
                } else {
                    this.error.hasError = false
                    this.error.message = ''
                }

                this.isLoading = true

                let paymentParams = new URLSearchParams({
                    action: 'commerce/payments/pay',
                    [window.pageInfo.csrfTokenName]: window.pageInfo.csrfToken,
                    gatewayId: 2,
                    ...checkoutForm.fields
                })

                let orderParams = new URLSearchParams({
                    action: 'commerce/cart/update-cart',
                    [window.pageInfo.csrfTokenName]: window.pageInfo.csrfToken,
                    email: checkoutForm.fields.email,
                    billingAddressSameAsShipping: true
                })
                for (let index in checkoutForm.shippingAddress) {
                    if (checkoutForm.shippingAddress.hasOwnProperty(index)) {
                        orderParams.append('shippingAddress['+index+']', checkoutForm.shippingAddress[index])
                    }
                }
                for (let index in checkoutForm.fields) {
                    if (checkoutForm.fields.hasOwnProperty(index)) {
                        orderParams.append('fields['+index+']', checkoutForm.fields[index])
                    }
                }

                samplesAPI.postSamples(orderParams.toString())
                    .then(response => {
                        samplesAPI.submitOrder(paymentParams.toString())
                            .then(response => {
                                this.orderSubmitted = true
                            })
                            .catch(e => {
                                console.log(e)
                                this.isLoading = false
                            })
                    })
                    .catch(e => {
                        console.log(e)
                        this.isLoading = false
                    })
            },
            goToCheckout() {
                this.checkingOut = true
            },
            getSamples() {
                this.isLoadingSamples = true
                samplesAPI.getSamples({})
                    .then(response => {
                        // Get the sample binder and add it to the sample list
                        const sampleBinder = response.data.meta.sampleBinder
                        const sampleBox = response.data.meta.sampleBox
                        const samples = [
                            sampleBinder,
                            sampleBox,
                            ...response.data.data
                        ]
                        this.samples = samples
                        this.cartInfo = response.data.meta.currentCart

                        // Figure out what samples need to go in the cart
                        const cart = samples.filter(element => element.isInCart)
                        this.cart = cart.map(cartItem => ({
                            ...cartItem,
                            qty: 1,
                            note: '',
                            options: {}
                        }))

                        // Just in case for some reason there's more than one item in the cart
                        if (this.cart.length > 3) {
                            this.cart = this.cart.slice(0, 3)
                        }

                        // Then stop loading
                        this.isLoadingSamples = false
                    })
                    .catch(e => {
                        if (!(e instanceof Cancel)) {
                            console.log(e)
                            this.isLoadingSamples = false
                        }
                    })
            },
            addSample(sample) {
                this.isLoadingSamples = true
                if (this.cart.length < 3 && !sample.isInCart) {
                    sample.isInCart = true
                    this.cart = [ ...this.cart,
                        { ...sample,
                            qty: 1,
                            note: '',
                            options: {}
                        }
                    ]
                    // Make API call
                    if (this.cart.length) {
                        let params = new URLSearchParams({
                            action: 'commerce/cart/update-cart',
                            [window.pageInfo.csrfTokenName]: window.pageInfo.csrfToken
                        })

                        this.cart.forEach((item, index) => {
                            params.append('purchasables[][id]', item['id'])
                        })

                        samplesAPI.postSamples(params.toString())
                            .then(response => {
                                this.cartInfo = response.data.cart
                                this.isLoadingSamples = false
                            })
                            .catch(e => {
                                if (!(e instanceof Cancel)) {
                                    console.log(e)
                                    this.isLoadingSamples = false
                                }
                            })
                    }
                }
            },
            removeSample(itemToRemoveFromCart) {
                this.isLoadingSamples = true

                const sampleItem = find(this.samples, sample => sample.id === itemToRemoveFromCart.id)
                const cartIndex = this.cart.findIndex(element => element.id === itemToRemoveFromCart.id)
                sampleItem.isInCart = false

                if (cartIndex > -1) {
                    let params = new URLSearchParams({
                        action: 'commerce/cart/update-cart',
                        [window.pageInfo.csrfTokenName]: window.pageInfo.csrfToken
                    })


                    /**
                     * Explaining this because it took a long time to fix...
                     * The cart returned on initialization and the cart returned after an item is deleted, are an Array and an Object, respectively.
                     * Therefore, the same looping function doesn't work on both. This meant you couldn't delete two items in one session, you would have to refresh the page.
                     * So, I convert the initial Array to an Object, that way it's consistent whether you delete an item for the first time or second time. Seems roundabout I know,
                     * but it took me a long time to figure this one out efficiently and in a reasonable amount of code. Lodash to the rescue! - Lukas Coffey, Exhausted Developer
                     */
                    // Begin strange code
                    let lineItems = {}
                    if (this.cartInfo.lineItems instanceof Array) {
                        this.cartInfo.lineItems.forEach(item => {
                            lineItems[item.id] = item
                        })
                    } else {
                        lineItems = this.cartInfo.lineItems
                    }

                    let lineItemId = findKey(lineItems, { 'purchasableId': itemToRemoveFromCart.purchasableId.toString() })
                    // Just in case Craft decides to cast purcashableId to an int...
                    if (!lineItemId) {
                      lineItemId = findKey(lineItems, {'purchasableId': itemToRemoveFromCart.purchasableId})
                    }
                    // End strange code

                    if (lineItemId) {
                        params.append(`lineItems[${lineItemId}][remove]`, true)
                        samplesAPI.postSamples(params.toString())
                            .then(response => {
                                this.cartInfo = response.data.cart
                                const quantity = response.data.cart.totalQty
                                updateCartQuantity(quantity)
                                this.isLoadingSamples = false
                            })
                            .catch(e => {
                                if (!(e instanceof Cancel)) {
                                    this.isLoadingSamples = false
                                    console.log(e)
                                }
                            })
                        this.cart = [
                            ...this.cart.slice(0, cartIndex),
                            ...this.cart.slice(cartIndex + 1)
                        ]
                    }
                }
            }
        }
    }
</script>
