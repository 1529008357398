<template>
    <div class="select-view">
        <p class="view-text">View:</p>
        <div :class="activeViewClass" @click="$emit('toggleProductView')">
            <div class="select-block-view">
                <i class="icon-block-view"></i>
            </div>
            <div class="select-row-view">
                <i class="icon-row-view"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        blockViewActive: Boolean
    },
    computed: {
        activeViewClass() {
            return {
                'select-container': true,
                'row-active': !this.blockViewActive,
                'block-active': this.blockViewActive
            }
        }
    }
}
</script>

