<template>
    <div class="row step-column-wrap">

        <div class="large-4 medium-5 small-12 columns sample-section">
            <div class="small-12 text-center">
                <a id="blog-load-more" :style="{ opacity: isLoadingSamples ? 1 : 0 }" :class="{ loading: isLoadingSamples }">
                    <span class="more-blog-loading-icon"></span>
                </a>
            </div>
            <div class="column small-12">
                <h6 class="heading-title icon"><i class="icon-cart-sample"></i> SAMPLES IN CART</h6>
            </div>
            <ul id="form-products" class="medium-block-grid-2">
                <li v-for="cartItem in cart" class="form-product" :key="cartItem.id">
                    <a role="button" class="item-in-cart" :data-sample="cartItem.id">
                        <div class="columns small-12 image-sample-cart">
                            <span class="remove-icon" @click="$emit('remove-sample', cartItem)"></span>
                            <img width="400" height="400" :src="cartItem.image" class="attachment-medium_square size-medium_square" alt="" :srcset="cartItem.image + ' 400w'" sizes="(max-width: 400px) 100vw, 400px" />
                        </div>
                        <div class="columns small-12 sample-details">
                            <span>{{ cartItem.product.title }}</span>
                            <span>{{ cartItem.title }}</span>
                            <span v-if="cartItem.dimensions.width">{{ cartItem.dimensions.width }}" x {{ cartItem.dimensions.length }}"</span>
                        </div>
                    </a>
                </li>
            </ul>
            <!-- <div class="column">
                <button class="button naked button-arrow left-arrow no-margin" @click="$emit('back-to-samples')"><i class="icon-arrow-left"></i>GO BACK TO SAMPLES</button>
            </div> -->
        </div>

        <div v-if="isLoading" class="small-12 text-center">
            <a id="blog-load-more" :style="{ display: isLoading ? 'block' : 'none', opacity: isLoading ? 1 : 0 }" :class="{ loading: isLoading }">
                <h5>Submitting Sample Reqest...</h5>
                <span class="more-blog-loading-icon"></span>
            </a>
        </div>


        <div v-if="!isLoading" class="large-8 medium-7 small-12 columns checkout-form-section">
            <div v-if="error.hasError" class="column small-12">
                <h5 class="heading-title error-text" style="display:block;">{{ error.message }}</h5>
                <hr/>
            </div>

            <div class="column small-12">
                <h6 class="heading-title">CONTACT INFORMATION</h6>
                <p><span>*</span> required field</p>
            </div>
            <ValidationObserver v-slot="{ invalid }">
            <form class="column" @submit.prevent="handleFormSubmit">
                <div class="syrup-field hide">
                    <textarea class="" name="samples" id="form-products-output"></textarea>
                    <label for="form-products-output">Samples</label>
                </div>
                <div class="row">
                    <div class="columns medium-6">
                        <validation-provider name="First Name" autocomplete="given-name" rules="required" v-slot="{errors, classes}">
                        <div class="syrup-field" :class="classes" >
                            <input type="text" id="firstname" v-model="checkoutForm.shippingAddress.firstName" placeholder="First Name*" required>
                            <label for="firstname">First Name</label>
                        </div>
                        </validation-provider>
                    </div>
                    <div class="columns medium-6">
                        <validation-provider name="Last Name" rules="required" v-slot="{errors, classes}">
                        <div class="syrup-field" :class="classes" >
                            <input type="text" id="lastname" autocomplete="family-name" v-model="checkoutForm.shippingAddress.lastName" placeholder="Last Name*" required>
                            <label for="lastname">Last Name</label>
                        </div>
                        </validation-provider>
                    </div>
                </div>
                <div class="row">
                    <div class="columns medium-6">
                        <validation-provider name="Email" rules="required|email" v-slot="{errors, classes}">
                        <div class="syrup-field" :class="classes">
                            <input type="email" id="email" autocomplete="email" v-model="checkoutForm.fields.email" placeholder="Email*" required>
                            <label for="email">Email</label>
                        </div>
                        </validation-provider>
                    </div>
                    <div class="columns medium-6">
                        <validation-provider name="Phone" rules="required" v-slot="{errors, classes}">
                        <div class="syrup-field" :class="classes">
                            <input type="tel" autocomplete="tel" id="phone" v-model="checkoutForm.shippingAddress.phone" placeholder="Phone*" required>
                            <label for="phone">Phone</label>
                        </div>
                        </validation-provider>
                    </div>
                </div>
                <div class="row">
                    <div class="column small-12">
                        <h6 class="heading-title">ADDRESS</h6>
                    </div>
                </div>
                <validation-provider name="Street Address" rules="required" v-slot="{errors, classes}">
                <div class="syrup-field" :class="classes">
                    <input type="text" id="street" autocomplete="street-address" v-model="checkoutForm.shippingAddress.address1" placeholder="Street Address*" required>
                    <label for="street">Street Address</label>
                </div>
                </validation-provider>
                <div class="row">
                    <div class="columns medium-4">
                        <validation-provider name="City" rules="required" v-slot="{errors, classes}">
                        <div class="syrup-field" :class="classes">
                            <input type="text" id="city" autocomplete="address-level2" v-model="checkoutForm.shippingAddress.city" placeholder="City*" required>
                            <label for="city">City</label>
                        </div>
                        </validation-provider>
                    </div>
                    <div class="columns medium-8">
                        <div class="row">
                            <div class="columns small-7">
                                <validation-provider name="State" rules="required" v-slot="{errors, classes}">
                                <div class="syrup-field" :class="classes" >
                                    <select required v-model="checkoutForm.shippingAddress.stateId">
                                        <option value="" disabled>State*</option>
                                        <option v-for="(stateId, stateName) in states" :key="stateName" :value="stateId">{{stateName}}</option>
                                    </select>
                                </div>
                                </validation-provider>
                            </div>
                            <div class="columns small-5">
                                <validation-provider name="Zip Code" rules="required" v-slot="{errors, classes}">
                                <div class="syrup-field" :class="classes">
                                    <input type="text" id="zip" autocomplete="postal-code" v-model="checkoutForm.shippingAddress.zipCode" placeholder="ZIP*" required>
                                    <label for="zip">ZIP</label>
                                </div>
                                </validation-provider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="columns">
                        <div class="commercial-fieldset nydree-checkbox">
                            <input id="commercial"  type="checkbox" v-model="checkoutForm.fields.orderAddressIsBusiness" value="Commercial">
                            <label for="commercial">This is a Business Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column small-12">
                        <h6 class="heading-title">PROJECT INFORMATION</h6>
                    </div>
                </div>
                <div class="syrup-field">
                    <div class="project-timeline-fieldset">
                        <p class="project-timeline-heading">Project Timeline</p>
                        <ul class="medium-block-grid-3 medium-7">
                            <li>
                                <input type="radio" id="months3" value="3 Months" v-model="checkoutForm.fields.orderProjectTimeline" checked="checked">
                                <label for="months3">3 Months</label>
                            </li>
                            <li>
                                <input type="radio" id="months6" value="6 Months" v-model="checkoutForm.fields.orderProjectTimeline">
                                <label for="months6">6 Months</label>
                            </li>
                            <li>
                                <input type="radio" id="months9" value="9+ Months" v-model="checkoutForm.fields.orderProjectTimeline">
                                <label for="months9">9+ Months</label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="checkoutForm.fields.orderAddressIsBusiness" class="syrup-field" id="company-contain">
                    <input type="text" id="company" v-model="checkoutForm.fields.orderCompanyName" placeholder="Company Name">
                    <label for="company">Company Name</label>
                </div>
                <div class="row">
                    <div class="columns medium-6">
                        <validation-provider name="Project Name" rules="required" v-slot="{errors, classes}">
                        <div class="syrup-field" :class="classes">
                            <input type="text" id="projectname" v-model="checkoutForm.fields.orderProjectName" placeholder="Project Name*" required>
                            <label for="projectname">Project Name</label>
                        </div>
                        </validation-provider>
                    </div>
                    <div class="columns medium-6">
                        <validation-provider name="Project Category" rules="required" v-slot="{errors, classes}">
                        <div class="syrup-field">
                            <select v-model="checkoutForm.fields.orderProjectCategory" required>
                                <option value="" disabled>Project Category*</option>
                                <option v-for="category in projectCategories" :key="category.value" :value="category.value">{{category.label}}</option>
                            </select>
                        </div>
                        </validation-provider>
                    </div>
                </div>
                <div class="syrup-field">
                    <textarea rows="8" class="" v-model="checkoutForm.fields.orderProjectComments" id="projectcomments" placeholder=""></textarea>
                    <label for="projectcomments">Project Comments</label>
                </div>
                <div class="text-right row">
                    <div class="column small-12">
                        <h6 v-if="error.hasError" class="error-text" style="display:block;">{{ error.message }}</h6>
                        <input type="submit" :disabled="invalid" value="SUBMIT" class="button naked">
                    </div>
                </div>
            </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
    import { extend } from 'vee-validate';
    import { required, email } from 'vee-validate/dist/rules';

    // No message specified.
    extend('email', email);

    // Override the default message.
    extend('required', {
        ...required,
        message: 'This field is required'
    });

export default {
    props: {
        cart: Array,
        isLoading: Boolean,
        isLoadingSamples: Boolean,
        error: Object
    },
    data() {
        return {
            states: window.states,
            currentStateName: '',
            categoryDropdownOpen: false,
            stateDropdownOpen: false,
            projectCategories: window.projectCategories,
            checkoutForm: {
                shippingAddress: {
                    firstName: '',
                    lastName: '',
                    address1: '',
                    phone: '',
                    city: '',
                    zipCode: '',
                    stateId: '',
                    countryId: 233 // USA
                },
                fields: {
                    shippingMethod: 'freeShipping',
                    email: '',
                    orderAddressIsBusiness: false,
                    orderCompanyName: '',
                    orderProjectTimeline: '3 Months',
                    orderProjectName: '',
                    orderProjectCategory: '',
                    orderProjectComments: ''
                }
            }
        }
    },
    methods: {
        handleFormSubmit(e) {
            this.$emit('submit-order', this.checkoutForm)
        }
    }
}
</script>
