var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sample-nav", {
        attrs: {
          cart: _vm.cart,
          max: 3,
          step: _vm.step,
          cartInfo: _vm.cartInfo
        },
        on: {
          "go-to-checkout": _vm.goToCheckout,
          "back-to-samples": _vm.backToSamples
        }
      }),
      _vm._v(" "),
      _vm.step != 3
        ? _c(
            "div",
            { staticClass: "panel-container" },
            [
              _c("sample-list", {
                attrs: { isLoading: _vm.isLoading, samples: _vm.samples },
                on: {
                  "add-sample": _vm.addSample,
                  "remove-sample": _vm.removeSample
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 3
        ? _c(
            "div",
            { staticClass: "panel-container" },
            [
              _c("checkout-screen", {
                attrs: {
                  isLoading: _vm.isLoading,
                  isLoadingSamples: _vm.isLoadingSamples,
                  cart: _vm.cart,
                  error: _vm.error
                },
                on: {
                  "back-to-samples": _vm.backToSamples,
                  "remove-sample": _vm.removeSample,
                  "submit-order": _vm.submitOrder
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }