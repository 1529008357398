<template>
    <div>
        <div class="modal-cmp-content-wrapper" v-if="show" ref="modal"></div>
    </div>
</template>

<script>
    import projectsApi from '../../js/components/projects/api';

    export default {
        name: 'ProjectModalApp',

        components: {},

        data() {
            return {
                show: false,
                isLoading: true,
                isCanonical: false,
                imageData: [],
                projectData: {},
                originalPageTitle: window.nydree.entry.seo.title
            }
        },

        watch: {
            show: function () {
                this[this.show ? 'open' : 'close']();
            }
        },

        created() {
            this.$eventBus.$on('view-project', this.loadProject.bind(this));
            window.addEventListener('popstate', (event) => {
                const state = event.state
                if (state && state.action && state.action === 'view-project') {
                    this.loadProject(state);
                } else if(state && state.action && state.action === 'view-projects') {
                    this.close(false);
                }
            }, false);
        },

        mounted() {
            // If we are on the actual project's entry URL, load it. Don't push state
            if(window.nydree.loadProject) {
                this.loadProject({
                    slug: window.nydree.loadProject,
                    canonical: true
                })
                history.replaceState({action: 'view-project', slug: window.nydree.loadProject, canonical: true}, '', '');
            }
        },

        methods: {
            open: function () {
                if (!!$.magnificPopup.instance.isOpen && this.show) {
                    return;
                }

                var cmp = this;
                var config = {
                    type: 'image',
                    preloader: true,
                    removalDelay: 300,
                    mainClass: 'mfp-fade',
                    markup: '<div class="mfp-figure"><div class="mfp-close"><span class="icon-close"></span></div>' +
                        '<figure>' +
                        '<figcaption>' +
                        '</figcaption>' +
                        '</figure>',
                    titleSrc: 'title',
                    gallery: {
                        enabled: true
                    },
                    items: this.imagesToDisplay,
                    callbacks: {
                        open: function () {
                            cmp.show = true;
                            cmp.$emit('open');
                        },
                        close: cmp.close
                    }
                };

                $.magnificPopup.open(config);
            },
            close: function (shouldPushState = true) {
                if (!$.magnificPopup.instance.isOpen && !this.show) {
                    return;
                }

                this.show = false;
                $.magnificPopup.close();
                this.$emit('close');
                if (shouldPushState && this.projectData && this.projectData.slug) {
                    if (this.isCanonical) {
                        const urlParts = location.href.split('/');
                        urlParts.pop();
                        const finalUrl = urlParts.join('/');
                        document.title = this.originalPageTitle;
                        history.pushState({action: 'view-projects'}, this.originalPageTitle, finalUrl);
                    } else {
                        history.pushState({action: 'view-projects'}, this.originalPageTitle, location.href.split(location.hash || "#")[0]);
                    }
                }
            },
            toggle: function () {
                this[this.show ? 'close' : 'open']();
            },
            async loadProject({slug, canonical = false}) {
                if (window.nydree.entry.type === 'inspirationLanding') {
                    canonical = true;
                }
                const projectData = await projectsApi.getProjectBySlug(slug);
                this.projectData = projectData.data;
                this.imageData = projectData.data.projectImages.data;
                this.isCanonical = canonical;
                this.open();
                if (typeof (window.history.pushState) == 'function') {
                    if (!canonical) {
                        history.pushState({action: 'view-project', slug: slug, canonical: canonical}, this.projectData.seo.title, '#view-project:' + slug)
                    } else {
                        document.title = this.projectData.seo.title;
                        history.pushState({action: 'view-project', slug: slug, canonical: canonical}, this.projectData.seo.title, this.projectData.url)
                    }
                }
            }
        },
        beforeMount: function () {
            this[this.show ? 'open' : 'close']();
        },
        computed: {
            imagesToDisplay() {
                return this.imageData.map((image) => {
                    return {
                        'src': image.urls['1x'],
                        'title': image.title
                    }
                })
            }
        }
    }
</script>
