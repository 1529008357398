<template>
    <div class="product-filter-container" v-bind:class="{ 'small-12 large-4 columns': true, 'open': popoutOpen }">
        <div class="filter-toggle" @click="$emit('togglePopout')">
            <h4>FILTER</h4>
            <img :src="icons.filter" />
        </div>
        <div class="filter-popout">
            <div class="close-icon" @click="$emit('togglePopout')">
                <img :src="icons.filterClose" />
            </div>

            <div class="filter-row">
                <h5>COLOR:</h5>
                <div class="color-spectrum">
                    <ColorPoints :colors="filters.productColors" />
                </div>
                <ul class="color-droplets">
                    <li v-for="color in filters.productColors" :key="color.id">
                        <label :for="color.id">
                            <input type="checkbox" :id="color.id" name="colorOptions" :value="color.id" v-model.number="metaFilters.colorFilters" />
                            <span class="checkmark droplet">
                                <span class="checkmark-icon"></span>
                            </span>
                            <span class="label">{{ color.title }}</span>
                        </label>
                    </li>
                </ul>
                <a class="clear-category-filters" @click="clearMetaCategoryFilters(filters.productPrices, 'colorFilters')">Clear All Color Filters</a>
            </div>

            <div class="filter-row">
                <h5>WIDTH:</h5>
                <ul class="green-checkboxes">
                    <li v-for="width in filters.productWidths" :key="width.id">
                        <label :for="width.id">
                            <input type="checkbox" :id="width.id" name="widthOptions" :value="width.id" v-model.number="metaFilters.widthOptions" />
                            <span class="checkmark"></span>
                            <span class="label">{{ width.title }}</span>
                        </label>
                    </li>
                </ul>
                <a class="clear-category-filters" @click="clearMetaCategoryFilters(filters.productWidths, 'widthOptions')">Clear All Width Filters</a>
            </div>

          <div class="filter-row">
            <h5>COLLECTION:</h5>
            <ul class="green-checkboxes full-width" v-for="collectionCategory in filters.productCollectionCategories">
              <div v-if="collectionCategory.children.length">{{collectionCategory.title}}</div>
              <li v-for="collection in collectionCategory.children" v-if="collection.children.length == 0" :key="collection.id">
                <label :for="collection.id">
                  <input type="checkbox" :id="collection.id" name="collectionOptions" :value="collection.id" v-model.number="metaFilters.collectionOptions" />
                  <span class="checkmark"></span>
                  <span class="label">{{ collection.title }}</span>
                </label>
              </li>
            </ul>
            <a class="clear-category-filters" @click="clearMetaCategoryFilters(filters.productCollectionCategories, 'collectionOptions')">Clear All Collection Filters</a>
          </div>

            <div class="filter-row">
                <h5>SPECIES:</h5>
                <ul class="green-checkboxes">
                    <li v-for="species in filters.productSpecies" :key="species.id">
                        <label :for="species.id">
                            <input type="checkbox" :id="species.id" name="speciesOptions" :value="species.id" v-model.number="metaFilters.speciesOptions" />
                            <span class="checkmark"></span>
                            <span class="label">{{ species.title }}</span>
                        </label>
                    </li>
                </ul>
                <a class="clear-category-filters" @click="clearMetaCategoryFilters(filters.productSpecies, 'speciesOptions')">Clear All Species Filters</a>
            </div>

            <div class="filter-row">
                <h5>PRICE:</h5>
                <ul class="green-checkboxes prices">
                    <li v-for="price in filters.productPrices" :key="price.id">
                        <!-- <div v-if="product.product" class="product-tip white">
                            <ul class="dollar">
                                <span v-for="n in 4" :key="n*437" :class="{ curr: true, dark: n < price.title.length }">$</span>
                            </ul>
                        </div> -->
                        <label :for="price.id">
                            <input type="checkbox" :id="price.id" name="priceOptions" :value="price.id" v-model.number="metaFilters.priceOptions" />
                            <span class="checkmark"></span>
                            <p class="label">
                                <span v-for="n in 4" :key="n*437" :class="{ curr: true, dark: n <= price.title.length }">$</span>
                            </p>
                        </label>
                    </li>
                </ul>
                <a class="clear-category-filters" @click="clearMetaCategoryFilters(filters.productPrices, 'priceOptions')">Clear All Price Filters</a>
            </div>

            <button type="button" class="button clear-all-filters" @click="clearAllFilters">CLEAR ALL FILTERS</button>
        </div>
    </div>
</template>
<script>
import ColorPoints from './ColorPoints'
import iconFilter from '../../images/svgs/filter.svg'
import iconFilterClose from '../../images/svgs/close.svg'

export default {
    props: ['popoutOpen', 'filters'],
    components: {
        ColorPoints
    },
    data() {
        return {
            metaFilters: {
                colorFilters: [],
                speciesOptions: [],
                collectionOptions: [],
                priceOptions: [],
                widthOptions: []
            },
            icons: {
                filter: iconFilter,
                filterClose: iconFilterClose
            }
        }
    },
    computed: {},
    watch: {
        metaFilters: {
            handler: function(newFilters, oldFilters) {
                this.$emit('metaFiltersChanged', newFilters)
            },
            deep: true
        }
    },
    methods: {
        clearAllFilters() {
            this.metaFilters = {
                colorFilters: [],
                speciesOptions: [],
                collectionOptions: [],
                priceOptions: [],
                widthOptions: []
            }
        },
        clearMetaCategoryFilters(categories, type) {
            const vm = this
            this.metaFilters[type] = [];
        }
    }
}
</script>
