<template>
    <li :class="{ 'product-listing samples-layout': true, 'active': sample.isInCart }">
        <a role="button">
            <div class="product-image">
                <img :src="sample.image"
                    width="400" height="400"
                     class="attachment-medium_square size-medium_square" alt=""
                     sizes="(max-width: 400px) 100vw, 400px"/>
                <div class="overlay-inner">
                    <form>
                        <legend>
                            <i class="icon-cart-sample"></i>
                        </legend>
                    </form>
                </div>
                <div class="addRemoveCart">
                    <span class="addCart" @click="$emit('add-sample', $event, sample)">
                        <i class="icon-plus"></i> ADD TO CART
                    </span>
                    <span class="removeCart" @click="$emit('remove-sample', sample, index)">
                        <i class="icon-minus"></i> REMOVE FROM CART
                    </span>
                </div>
            </div>
            <div class="detail-product">
                {{ sample.title }}<br>
                <span>{{ sample.product.title }}</span>
            </div>
        </a>
    </li>
</template>
<script>
    import upperFirst from 'lodash/upperFirst';
    import camelCase from 'lodash/camelCase';

    export default {
        props: {
            index: Number,
            sample: Object
        },
        methods: {},
        computed: {
            dimensionValue() {
                const sample = this.sample
                if (sample.dimensions.width && sample.dimensions.length) {
                    return upperFirst(camelCase(sample.title)) + sample.dimensions.width + 'x' + sample.dimensions.length
                } else {
                    return sample.dimensions
                }
            }
        }
    }
</script>
