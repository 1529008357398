<template>
    <li class="product-listing" data-equalizer-watch>
        <div class="product-listing-full" :style="{ backgroundImage: collection.productImage ? `url('${ collection.productImage }')` : null }" data-equalizer-watch>

            <div class="top-section">
                <a :href="collection.url"
                   class="floatLink"></a>
                <div class="heading">
                    <img v-if="collection.logo" :src="collection.logo" alt="">
                    <hgroup>
                        <h3>{{ collection.title }}</h3>
                        <p>{{ collection.productSubheading }}</p>
                    </hgroup>
                </div>
            </div>

            <div class="bottom-section">
                <ul class="color-option">
                    <li v-for="option in collection.colorOptions" :key="option.id" class="wrap">
                        <a data-colorpallete data-prodtooltip
                            :data-image="option.colorOptionPhoto"
                            :style="{ backgroundImage: `url('${ option.colorOptionPhoto }')` }"
                            role="button" class="color"></a>
                        <div class="colorTip">
                            <div class="text">{{ option.title }}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </li>
</template>

<script>
export default {
    props: {
        collection: Object
    }
}
</script>
