var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { gallerycard: true, "gallerycard--embedded": this.embedded } },
    [
      _c(
        "a",
        {
          staticClass: "gallerycard__imagecontainer",
          attrs: { href: "#" },
          on: { click: _vm.handleClick }
        },
        [
          _c("img", {
            attrs: {
              src: _vm.photo[0]["url"],
              alt: _vm.title,
              srcset: _vm.photo
                .map(function(image) {
                  return image.url + " " + image.width + "w"
                })
                .join(","),
              sizes: "(max-width: 1000px) 1400px,\n     700px"
            }
          }),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.location))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gallerycard__content" },
        [
          _c(
            "a",
            {
              staticClass: "gallerycard__title",
              attrs: { href: "#" },
              on: { click: _vm.handleClick }
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "gallerycard__description",
            domProps: { innerHTML: _vm._s(_vm.description) }
          }),
          _vm._v(" "),
          _vm._l(_vm.validProductBlocks, function(productBlock) {
            return _c("div", { key: productBlock.id }, [
              productBlock.product
                ? _c("div", { staticClass: "gallerycard__productname" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: productBlock.product.url,
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(productBlock.product.title))]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "gallerycard__colors" }, [
                _c(
                  "ul",
                  _vm._l(productBlock.featuredColors, function(color) {
                    return _c("li", { key: color.id }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: productBlock.product.url + "#" + color.slug
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              alt: color.title,
                              src: color.colorOptionPhoto["1x"],
                              srcset:
                                color.colorOptionPhoto["1x"] +
                                " 1x, " +
                                color.colorOptionPhoto["2x"]
                            }
                          }),
                          _c("span", [_vm._v(_vm._s(color.title))])
                        ]
                      )
                    ])
                  }),
                  0
                )
              ])
            ])
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }