<template>
    <div>
        <div v-if="!isLoading && productColorOptions.length && !error.hasError">
            <ProductBlock
                v-for="(colorOption, index) in productColorOptions"
                v-if="colorOption.collection"
                :key="colorOption.uid"
                :samplesInCart="samplesInCart"
                :colorOption="colorOption"
                :index="index"
                v-on:add-to-cart="handleATC" />
        </div>
        <div v-else-if="error.hasError && !isLoading" class="no-results error-state">
            <p>There was an error, please try again or contact us if this keeps happening.</p>
            <p class="error-message">Error: {{ error.message }}</p>
        </div>
        <div v-else-if="!isLoading && !productColorOptions.length" class="no-results">
            No results.
        </div>
        <div v-if="isLoading" class="small-12 text-center">
            <a id="blog-load-more" :style="{ display: isLoading ? 'block' : 'none', opacity: isLoading ? 1 : 0 }" :class="{ loading: isLoading }">
                Loading products...
                <span class="more-blog-loading-icon"></span>
            </a>
        </div>
    </div>
</template>

<script>
import ProductBlock from './ProductBlock'
export default {
    props: {
        isLoading: Boolean,
        productColorOptions: Array,
        error: Object,
        samplesInCart: Array
    },
    components: {
        ProductBlock
    },
    methods: {
        handleATC(variant, index) {
            this.$emit('add-to-cart', variant, index)
        }
    }
}
</script>

