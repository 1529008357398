var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "projectgallery__filters" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row filterscontainer" }, [
          _c("div", { staticClass: "columns large-3 medium-6 small-12" }, [
            _c("div", { staticClass: "projectgalleryfilter__label" }, [
              _vm._v("Select a Product")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nydree-select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedProduct,
                      expression: "selectedProduct"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedProduct = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("All Products")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.computedProductCategories, function(product) {
                    return _c(
                      "option",
                      { key: product.id, domProps: { value: product.slug } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(product.title) +
                            "\n                        "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns large-3 medium-6 small-12" }, [
            _c("div", { staticClass: "projectgalleryfilter__label" }, [
              _vm._v("Select an Industry")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nydree-select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedIndustry,
                      expression: "selectedIndustry"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedIndustry = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("All Industries")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.computedIndustryCategories, function(industry) {
                    return _c(
                      "option",
                      { key: industry.id, domProps: { value: industry.slug } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(industry.title) +
                            "\n                        "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns large-3 medium-6 small-12" }, [
            _c("div", { staticClass: "projectgalleryfilter__label" }, [
              _vm._v("Select a Region")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nydree-select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedRegion,
                      expression: "selectedRegion"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedRegion = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("All Regions")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.computedRegionCategories, function(region) {
                    return _c(
                      "option",
                      { key: region.id, domProps: { value: region.slug } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(region.title) +
                            "\n                        "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns large-3 medium-6 small-12" }, [
            _c("div", { staticClass: "projectgalleryfilter__label" }, [
              _vm._v("Select a Pattern")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nydree-select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedPattern,
                      expression: "selectedPattern"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedPattern = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("All Patterns")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.computedPatternCategories, function(pattern) {
                    return _c(
                      "option",
                      { key: pattern.id, domProps: { value: pattern.slug } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(pattern.title) +
                            "\n                  "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "columns large-3 medium-6 small-12 projectgalleryfilter__clearbutton"
            },
            [
              _vm.filtersAreDirty && !_vm.isLoading && !_vm.isLoadingMore
                ? _c("button", { on: { click: _vm.clearFilters } }, [
                    _vm._v(
                      "Clear All\n                    Filters\n                "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "columns medium-12 projectgalleryfilter__stairs nydree-checkbox"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.stairsOnly,
                    expression: "stairsOnly"
                  }
                ],
                attrs: { type: "checkbox", id: "stairsonly" },
                domProps: {
                  checked: Array.isArray(_vm.stairsOnly)
                    ? _vm._i(_vm.stairsOnly, null) > -1
                    : _vm.stairsOnly
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.stairsOnly,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.stairsOnly = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.stairsOnly = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.stairsOnly = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "stairsonly" } }, [
                _vm._v("Show only projects with stairs")
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.isLoading
        ? _c("loader", { style: { textAlign: "center" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "masonry",
        {
          attrs: {
            cols: { default: 3, 1000: 2, 700: 2, 700: 1 },
            gutter: { default: "54px", 1200: "30px", 700: "15px" }
          }
        },
        _vm._l(_vm.projects, function(project, index) {
          return _vm.projects.length
            ? _c("gallery-card", {
                key: project.id,
                attrs: {
                  description: project.projectCardCopy,
                  slug: project.slug,
                  photo: project.featuredPhoto,
                  title: project.title,
                  products: project.featuredProducts.data,
                  location: project.location
                }
              })
            : _vm._e()
        }),
        1
      ),
      _vm._v(" "),
      !_vm.projects.length && !_vm.isLoading
        ? _c("div", [_vm._v("There are no projects matching those filters.")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "small-12 columns load-more-container" },
        [
          _vm.hasMorePages
            ? _c("load-more-button", {
                attrs: {
                  "is-loading": _vm.isLoading,
                  "is-loading-more": _vm.isLoadingMore
                },
                on: { loadMore: _vm.handleLoadMore }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "columns medium-12 filterstitle" }, [
        _c("div", [_c("h4", [_vm._v("FILTER PROJECTS")])])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }