<template>
    <div class="featuredprojects">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="project in projects" :key="project.id">
                <project-card
                                :embedded="true"
                              :description="project.projectCardCopy"
                              :slug="project.slug"
                              :photo="project.featuredPhoto"
                              :title="project.title"
                              :products="project.featuredProducts.data"
                              :location="project.location" />
            </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" ref="prevButton"></div>
        <div class="swiper-button-next" ref="nextButton"></div>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import ProjectCard from "../NydreeUI/ProjectCard";
    import 'swiper/css/swiper.css'
    import api from '../../js/components/projects/api';

    export default {
        name: 'FeaturedProjectsModuleApp',

        components: {
            Swiper,
            SwiperSlide,
            ProjectCard
        },
        directives: {
            swiper: directive
        },
        data() {
            return {
                swiperOptions: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        1020: {
                            slidesPerView: 3
                        },
                        700: {
                            slidesPerView: 2
                        },
                        400: {
                            slidesPerView: 1
                        }
                    }
                },
                projects: []
            }
        },

        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper
            }
        },

        created() {
            this.getProjects();
        },

        watch: {

        },

        methods: {
            getProjects() {
                const entrySlug = window.nydree.entry.slug;
                api.getFilteredProjects({
                    product: entrySlug
                }).then((result) => {
                    this.projects = result.data.data;
                    this.swiper.update();
                })
            }
        },
    }
</script>

<style type="text/scss">
    @import "../../sass/settings";
    .featuredprojects {
        .swiper-container {
            width: 90%;
            position: relative;
        }
        .swiper-button-prev, .swiper-button-next {
            transition: all .2s ease;
            background-color: #ffffff;
            width: 50px;
            height: 50px;
            box-sizing: border-box;
            border: 1px solid rgba(104,107,110,0.5);
            font-family: 'nydree-icons';
            &:after {
                font-family: 'nydree-icons';
                font-size: 13px;
                color: #2E423B !important;
                transition: all .2s ease;
            }
        }
        .swiper-button-prev:after{
            content: "\e901";
        }
        .swiper-button-next:after{
            content: "\e900";
        }
    }
</style>