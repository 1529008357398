<template>
    <div>
        <div class="projectgallery__filters">
            <div class="row">
                <div class="columns medium-12 filterstitle">
                    <div><h4>FILTER PROJECTS</h4></div>
                </div>
            </div>
            <div class="row filterscontainer">
                <div class="columns large-3 medium-6 small-12">
                    <div class="projectgalleryfilter__label">Select a Product</div>
                    <div class="nydree-select">
                        <select v-model="selectedProduct">
                            <option value="">All Products</option>
                            <option v-for="product in computedProductCategories" :key="product.id"
                                    :value="product.slug">
                                {{product.title}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="columns large-3 medium-6 small-12">
                    <div class="projectgalleryfilter__label">Select an Industry</div>
                    <div class="nydree-select">
                        <select v-model="selectedIndustry">
                            <option value="">All Industries</option>
                            <option v-for="industry in computedIndustryCategories" :key="industry.id"
                                    :value="industry.slug">
                                {{industry.title}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="columns large-3 medium-6 small-12">
                    <div class="projectgalleryfilter__label">Select a Region</div>
                    <div class="nydree-select">
                        <select v-model="selectedRegion">
                            <option value="">All Regions</option>
                            <option v-for="region in computedRegionCategories" :key="region.id" :value="region.slug">
                                {{region.title}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="columns large-3 medium-6 small-12">
                  <div class="projectgalleryfilter__label">Select a Pattern</div>
                  <div class="nydree-select">
                    <select v-model="selectedPattern">
                      <option value="">All Patterns</option>
                      <option v-for="pattern in computedPatternCategories" :key="pattern.id" :value="pattern.slug">
                        {{pattern.title}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="columns large-3 medium-6 small-12 projectgalleryfilter__clearbutton">
                    <button v-if="filtersAreDirty && !isLoading && !isLoadingMore" @click="clearFilters">Clear All
                        Filters
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="columns medium-12 projectgalleryfilter__stairs nydree-checkbox">
                    <input type="checkbox" id="stairsonly" v-model="stairsOnly"/>
                    <label for="stairsonly">Show only projects with stairs</label>
                </div>
            </div>
        </div>
        <loader :style="{textAlign: 'center'}" v-if="isLoading"/>
        <masonry
                :cols="{default: 3, 1000: 2, 700: 2, 700: 1}"
                :gutter="{default: '54px', 1200: '30px', 700: '15px'}"
        >
            <gallery-card v-if="projects.length" v-for="(project, index) in projects" :key="project.id"
                          :description="project.projectCardCopy"
                          :slug="project.slug"
                          :photo="project.featuredPhoto"
                          :title="project.title"
                          :products="project.featuredProducts.data"
                          :location="project.location"/>
        </masonry>
        <div v-if="!projects.length && !isLoading">There are no projects matching those filters.</div>

        <div class="small-12 columns load-more-container">
            <load-more-button v-if="hasMorePages" :is-loading="isLoading" :is-loading-more="isLoadingMore"
                              v-on:loadMore="handleLoadMore"></load-more-button>
        </div>
    </div>
</template>

<script>
    import GalleryCard from '../NydreeUI/ProjectCard'
    import projectsApi from "../../js/components/projects/api";
    import {Cancel} from "axios";
    import LoadMoreButton from "../NydreeUI/LoadMoreButton";
    import Loader from "../NydreeUI/Loader";

    export default {
        name: 'ProjectsGalleryApp',

        components: {
            Loader,
            LoadMoreButton,
            GalleryCard
        },

        data() {
            return {
                page: 1,
                isLoading: true,
                isLoadingMore: false,

                pagination: null,
                meta: null,
                projects: [],

                filterOrder: [],

                selectedProduct: '',
                selectedIndustry: '',
                selectedRegion: '',
                selectedPattern: '',
                stairsOnly: false,

                // These are the initial categories we receive for unfiltered results
                productCategories: [],
                industryCategories: [],
                regionCategories: [],
                patternCategories: [],

                // These are the filtered categories we receive
                productCategoriesFiltered: [],
                industryCategoriesFiltered: [],
                regionCategoriesFiltered: [],
                patternCategoriesFiltered: [],

                error: {
                    hasError: false,
                    message: null
                }
            }
        },

        computed: {
            hasMorePages() {
                if (!this.pagination) {
                    return false;
                }
                if (this.pagination && this.pagination.links.next) {
                    return true;
                }
            },
            filtersAreDirty() {
                return (this.selectedProduct || this.selectedIndustry || this.selectedRegion);
            },
            computedProductCategories() {
                if (this.filterOrder.length > 0 && this.filterOrder[0] === 'product') {
                    return this.productCategories;
                }
                return this.productCategoriesFiltered;
            },
            computedIndustryCategories() {
                if (this.filterOrder.length > 0 && this.filterOrder[0] === 'industry') {
                    return this.industryCategories;
                }
                return this.industryCategoriesFiltered;
            },
            computedRegionCategories() {
                if (this.filterOrder.length > 0 && this.filterOrder[0] === 'region') {
                    return this.regionCategories;
                }
                return this.regionCategoriesFiltered;
            },
            computedPatternCategories() {
              if (this.filterOrder.length > 0 && this.filterOrder[0] === 'pattern') {
                return this.patternCategories;
              }
              return this.patternCategoriesFiltered;
            }
        },

        beforeMount() {
            this.getProjects().then(() => {
                this.productCategories = this.meta.productCategories;
                this.industryCategories = this.meta.industryCategories;
                this.regionCategories = this.meta.regionCategories;
                this.patternCategories = this.meta.patternCategories;
            }).finally(() => {
                this.isLoading = false;
            });
        },

        watch: {
            async selectedProduct(newValue, oldValue) {
                if (!oldValue) {
                    this.filterOrder.push('product');
                }
                if (!newValue) {
                    this.filterOrder = this.filterOrder.filter((item) => item != 'product');
                }
                this.resetAndReloadProjects()
            },
            async selectedIndustry(newValue, oldValue) {
                if (!oldValue) {
                    this.filterOrder.push('industry');
                }
                if (!newValue) {
                    this.filterOrder = this.filterOrder.filter((item) => item != 'industry');
                }
                this.resetAndReloadProjects()
            },
            async selectedRegion(newValue, oldValue) {
                if (!oldValue) {
                    this.filterOrder.push('region');
                }
                if (!newValue) {
                    this.filterOrder = this.filterOrder.filter((item) => item != 'region');
                }
                this.resetAndReloadProjects()
            },
            async selectedPattern(newValue, oldValue) {
              if (!oldValue) {
                this.filterOrder.push('pattern');
              }
              if (!newValue) {
                this.filterOrder = this.filterOrder.filter((item) => item != 'pattern');
              }
              this.resetAndReloadProjects()
            },
            async stairsOnly(newValue, oldValue) {
                this.resetAndReloadProjects()
            },
        },

        methods: {
            async resetAndReloadProjects() {
                this.page = 1;
                this.projects = [];
                this.isLoading = true;
                await this.getProjects()
                this.isLoading = false;
            },
            async handleLoadMore() {
                this.page += 1;
                this.isLoadingMore = true;
                await this.getProjects();
                this.isLoadingMore = false;
            },
            getCurrentFilterData() {
                return {
                    product: this.selectedProduct,
                    industry: this.selectedIndustry,
                    region: this.selectedRegion,
                    pattern: this.selectedPattern,
                    stairsOnly: this.stairsOnly,
                    page: this.page
                }
            },
            clearFilters() {
                this.selectedProduct = '';
                this.selectedIndustry = '';
                this.selectedRegion = '';
                this.page = 1;
                this.getProjects();
            },
            async getProjects(initial = false) {
                return new Promise((resolve, reject) => {
                    const data = this.getCurrentFilterData()
                    projectsApi.getFilteredProjects(data)
                        .then(response => {
                            const meta = response.data.meta
                            const projects = response.data.data
                            this.pagination = meta.pagination
                            this.meta = meta;
                            this.productCategoriesFiltered = this.meta.productCategories;
                            this.industryCategoriesFiltered = this.meta.industryCategories;
                            this.regionCategoriesFiltered = this.meta.regionCategories;
                            this.patternCategoriesFiltered = this.meta.patternCategories;
                            this.projects = this.projects.concat(projects)
                            resolve();
                        })
                        .catch(e => {
                            if (!(e instanceof Cancel)) {
                                console.error(e)
                                reject();
                            }
                            resolve();
                        })
                });
            },
        },
    }
</script>

<style type="text/scss">
    @import "../../sass/settings";

    .gallerycard-item {
        display: inline-block;
        margin-right: 10px;
    }
    .gallerycard-enter-active, .gallerycard-leave-active {
        transition: all 1s;
    }
    .gallerycard-enter, .gallerycard-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(30px);
    }

    .filterstitle {
        h4 {
            font-size: 18px;
            font-family: $font-family-avenir;
            font-weight: bold;
            color: $primary-color;
        }
    }

    .projectgalleryfilter__stairs {
        margin: 15px 0;
    }

    .filterscontainer {
        height: 80px;

        select {
            margin-bottom: 0;
        }
        .columns {
            margin-bottom: 20px;
        }
        @media #{$large-up} {
            .columns {
                margin-bottom: 0;
            }
        }
    }

    .projectgalleryfilter__label {
        color: #817F7C;
        font-family: $font-family-avenir;
        font-weight: 200;
        font-size: 16px;
        line-height: 18px;
        height: 28px;
    }

    .projectgalleryfilter__clearbutton {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;

        & > button {
            margin-bottom: 0;
            background: transparent;
            color: $primary-color;
            font-size: 14px;
            line-height: 16px;
            text-decoration: underline;
            padding: 0;
        }
        @media only screen and (min-width: 40.063em) {
            margin-top: 28px;
            & > button {
                padding: 16px 0;
            }
        }
    }
</style>